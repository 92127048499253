<template>
  <v-container>
    <v-col cols="12" sm="12" offset-sm="0">
      <v-card class="maincard">
        <v-container>
          <v-row align="center">
            <v-col cols="12" xs="12" sm="6">
              <v-card-title>Auftragsbetätigungen</v-card-title>
            </v-col>

            <v-col cols="12" xs="12" sm="6" style="text-align: right;">
              <v-btn to="/confirmation/edit" color="success">
                <v-icon style="margin-right: 10px;">mdi-file-plus</v-icon>
                <span>Neue Auftragsbestätigung</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <ItemList />
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
// @ is an alias to /src
import ItemList from "@/components/confirmations/ConfirmationList";
//import ImportDialog from "@/components/ImportDialog";

export default {
  name: "ConfirmationMgmt",
  data: () => ({
    mapFields: {
      status: "Status"
    }
  }),
  components: { ItemList },
  methods: {
    checkAccess(role) {
      const user = this.$store.getters["user/getUser"];

      if (!user) {
        return false;
      }
      if (
        user.apps.roles.indexOf(
          this.$store.getters["user/userRoles"].userRole.SuperUser
        ) >= 0
      ) {
        return true;
      } else if (user.apps.roles.indexOf(role) >= 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  computed: {},
  beforeCreate() {
   
  }
};
</script>
<style scoped></style>
